/** @jsx jsx */
import HomePageLayout from '../../../components/HomePageLayout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import Link from '@fify.at/gatsby-theme-base/src/components/Link';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';
import Divider from '@fify.at/gatsby-theme-base/src/components/Divider/Divider';

export default function Uebersiedelungsservice({ location, data }) {
  return (
    <HomePageLayout
      location={location}
      lang="de"
      title="Übersiedelungsservice – so klappt der Umzug ohne Stress!"
      decription="Eine Übersiedelung ist immer ein komplexer Vorgang und will gut geplant sein. Mit unserem maßgeschneiderten Übersiedelungsservice finden wir garantiert die passende Lösung für Ihren Umzug."
      image={data.hero.childImageSharp.fluid}
      keywords={['Übersiedelungsservice']}
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <HeroSectionContainer
        fluid={false}
        orientation="southEast"
        variant="alpha"
        title="Übersiedelungsservice"
        subtitle="So klappt der Umzug ohne Stress!"
        image={data.hero}
      />
      <Section container="small">
        <div sx={{ marginBottom: 7 }}>
          <Heading>Sie brauchen Hilfe beim Umzug?</Heading>
          <Subheading>Wir nehmen Ihnen die Arbeit ab!</Subheading>
        </div>
        <p>
          Ist das Haus erstmals verkauft, lässt der Übersiedelungszeitpunkt
          nicht lange auf sich warten.
        </p>
        <p>
          Eine Übersiedelung ist immer ein komplexer Vorgang und will gut
          geplant sein – unabhängig davon, wohin es gehen soll.
        </p>
        <p>
          Damit Sie Stress weitestgehend vermeiden, kann es sinnvoll sein, die
          Übersiedelung in die Hände einer erfahrenen Spedition zu geben – so
          können Sie versteckte Kosten vermeiden und böse Überraschungen bleiben
          aus.
        </p>

        <p>
          Da sich jeder Umzug anders gestaltet, ist es wichtig, dass das
          Übersiedelungsservice genau auf Ihre individuellen Bedürfnisse
          abgestimmt wird.
          <br />
          Dazu gehört auch eine kostenlose Besichtigung des Umzugsgutes bei
          Ihnen vor Ort. Hier werden die Anfordernisse Ihrer Übersiedlung
          besprochen und ein entsprechendes Angebot erstellt.
        </p>
        <p>
          Mit unserem maßgeschneiderten Übersiedelungsservice finden wir
          garantiert die passende Lösung für Ihren Umzug. Unsere Partner vor Ort
          beraten Sie dazu gerne. Wir freuen uns auf Ihre{' '}
          <Link to="/kontakt/">Kontaktaufnahme</Link>.
        </p>
      </Section>
    </HomePageLayout>
  );
}

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-uebersiedlungsservice" }) {
      childImageSharp {
        fluid(maxWidth: 2340, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
